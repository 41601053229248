<template>
  <s-button
    variant="primary"
    size="md"
    class="!min-w-[11.6rem] absolute right-0 top-[-85px] z-[26]"
    @click="navigateToTermForm(MODE.REGISTER)"
  >
    {{ $t('studio.prj_prod_mngmt.prod_home.prod_terms.create_btn') }}
  </s-button>
  <template v-if="!isLoading">
    <div
      v-if="hasTermsGuide"
      class="mt-24 flex justify-center items-center gap-4 px-40 py-8 bg-[#598AFF] rounded-full"
    >
      <s-icon size="3xl" icon="ic-v2-state-warning-circle-fill" class="text-white" />
      <p class="text-md font-medium leading-lg text-white">
        {{ $t('studio.prj_prod_mngmt.prod_home.create_term.usage_none_guide') }}
      </p>
    </div>
    <st-box>
      <template v-if="terms && terms.length === 0">
        <div class="flex flex-col items-center px-[1rem] py-8">
          <safe-html
            :html="$t('studio.prj_prod_mngmt.prod_home.prod_terms.guide_1')"
            tag="p"
            class="text-md font-medium leading-lg text-on-surface-elevation-3 text-center"
          />
          <div class="mt-24 flex gap-24">
            <div>
              <a :href="firstTermsHomeImageByLocale" target="_blank">
                <img
                  :src="firstTermsHomeImageByLocale"
                  alt=""
                  class="block w-[26.2rem] h-[22.6rem] object-cover"
                />
              </a>
              <safe-html
                :html="$t('studio.prj_prod_mngmt.prod_home.prod_terms.sample1')"
                tag="p"
                class="mt-[1rem] text-sm leading-md text-on-surface-elevation-3 text-center"
              />
            </div>
            <div>
              <a :href="secondTermsHomeImageByLocale" target="_blank">
                <img
                  :src="secondTermsHomeImageByLocale"
                  alt="terms-home-background"
                  class="block w-[26.2rem] h-[22.6rem] object-cover"
                />
              </a>
              <safe-html
                :html="$t('studio.prj_prod_mngmt.prod_home.prod_terms.sample2')"
                tag="p"
                class="mt-[1rem] text-sm leading-md text-on-surface-elevation-3 text-center"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex gap-8 items-center mb-8">
          <s-button variant="outline" size="xs" @click="handleProjectsFold(true)">
            {{ $t('studio.member_manage.project_list_collapse_all_btn') }}
          </s-button>
          <s-button variant="outline" size="xs" @click="handleProjectsFold(false)">
            {{ $t('studio.member_manage.project_list_expand_all_btn') }}
          </s-button>
        </div>
        <m-table>
          <colgroup>
            <col style="width: 18rem" />
            <col style="width: auto" />
            <col style="width: 28rem" />
            <col style="width: 16rem" />
          </colgroup>
          <thead>
            <tr>
              <st-th
                :tooltipUse="locale === 'en' ?? false"
                :thTitle="$t('studio.prj_prod_mngmt.prod_home.prod_terms.release_applied_term_ver')"
                :thTitleTooltip="
                  $t('studio.prj_prod_mngmt.prod_home.prod_terms.release_applied_term_ver')
                "
                thClass="!bg-[#F7FAFD]"
              />
              <st-th
                :thTitle="
                  $t('studio.prj_prod_mngmt.prod_home.prod_terms.release_applied_term_name')
                "
                thClass="!bg-[#F7FAFD]"
              />
              <st-th
                :thTitle="$t('studio.prj_prod_mngmt.prod_home.prod_terms.term_type')"
                thClass="!bg-[#F7FAFD]"
              />
              <st-th
                :tooltipUse="locale === 'en' ?? false"
                :thTitle="
                  $t('studio.prj_prod_mngmt.prod_home.prod_terms.release_applied_term_apply_date')
                "
                :thTitleTooltip="
                  $t('studio.prj_prod_mngmt.prod_home.prod_terms.release_applied_term_apply_date')
                "
                thClass="!bg-[#F7FAFD]"
              />
            </tr>
          </thead>
          <tbody>
            <tr v-for="term in terms" :key="term.termsRunning.subCategory" class="cursor-pointer">
              <td colspan="4">
                <div class="flex flex-col gap-8 mt-8">
                  <st-folding
                    v-model:isOpenDetail="term.expand"
                    termsType
                    :termsVersion="
                      term.termsRunning.version ? term.termsRunning.version.toUpperCase() : '-'
                    "
                    :termsTitle="term.termsRunning.name ?? '-'"
                    :termsSort="$t(getTermLabel(term.termsRunning.subCategory ?? ''))"
                    :termDate="getDateTimeByLocale(term.termsRunning.enforcedAt, true)"
                  >
                    <div class="mt-8 mx-24">
                      <m-table>
                        <colgroup>
                          <col style="width: 11.6rem" />
                          <col style="width: auto" />
                          <col style="width: 16rem" />
                          <col style="width: 16rem" />
                          <col style="width: 6.5rem" />
                        </colgroup>
                        <thead>
                          <tr>
                            <st-th
                              :thTitle="$t('studio.prj_prod_mngmt.prod_home.prod_terms.term_ver')"
                              thClass="!bg-[#F7FAFD]"
                            />
                            <st-th
                              :thTitle="$t('studio.prj_prod_mngmt.prod_home.prod_terms.term_name')"
                              thClass="!bg-[#F7FAFD]"
                            />
                            <st-th
                              :thTitle="
                                $t('studio.prj_prod_mngmt.prod_home.prod_terms.term_create_date')
                              "
                              thClass="!bg-[#F7FAFD]"
                            />
                            <st-th
                              :thTitle="
                                $t('studio.prj_prod_mngmt.prod_home.prod_terms.term_apply_date')
                              "
                              thClass="!bg-[#F7FAFD]"
                            />
                            <st-th thClass="!bg-[#F7FAFD]" />
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="term.contents.length > 0">
                            <st-tr
                              v-for="(version, index) in term.contents"
                              :key="`${version.versionId}-${version.name}`"
                              hasLink
                              @onRowClick="handleOnVersionClick(version.versionId)"
                            >
                              <st-td
                                aLeft
                                textMediumMedium
                                :tdValue="version.version ? version.version.toUpperCase() : '-'"
                              />
                              <st-conditional-tooltip-td
                                aLeft
                                :tdValue="version.name"
                                textClass="text-sm truncate block"
                              />
                              <st-td
                                aLeft
                                textMedium
                                :tdValue="getDateTimeByLocale(version.createdAt, true)"
                              />
                              <st-td
                                aLeft
                                textMedium
                                :tdValue="getDateTimeByLocale(version.enforcedAt, true)"
                              />
                              <st-td onlyButton>
                                <s-button
                                  v-if="isProductWritable && index === 0 && !version.enforcedAt"
                                  type="button"
                                  class="!h-12 !px-8"
                                  variant="outline"
                                  @click.stop="handleVersionDelete(version.versionId)"
                                >
                                  {{
                                    $t('studio.prj_prod_mngmt.prod_home.prod_terms.term_del_btn')
                                  }}
                                </s-button>
                              </st-td>
                            </st-tr>
                          </template>
                          <template v-else>
                            <st-td colspan="4" aCenter>
                              {{ $t('studio.member_manage.list_no_data') }}
                            </st-td>
                          </template>
                        </tbody>
                      </m-table>
                    </div>
                  </st-folding>
                </div>
              </td>
            </tr>
          </tbody>
        </m-table>
      </template>
    </st-box>
  </template>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';

import { checkTermGuide, deleteTermApi, fetchRunningTermsAndVersionApi } from '@/apis/terms.api';
import * as TermsHomeFirstImages from '@/assets/images/terms/terms-home-image-01';
import * as TermsHomeSecondImages from '@/assets/images/terms/terms-home-image-02';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StConditionalTooltipTd from '@/components/common/st-conditional-tooltip-td.vue';
import StFolding from '@/components/common/st-folding.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import StTr from '@/components/common/table/st-tr.vue';
import DeleteDialog from '@/components/terms/delete-dialog.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import { TERM_TYPES } from '@/constants/terms.const';
import { PRODUCT_TERMS_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import useProductStore from '@/stores/product.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type {
  RunningTermContents,
  RunningTermsAndVersionResponse
} from '@/types/terms/terms-response.type';
import { redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { getTermLabel } from '@/utils/term.util';

const MODE = {
  REGISTER: 'register',
  EDIT: 'edit'
};

const { t, locale } = useI18n();

const { checkProductPermission } = useApp();
const appStore = useAppStore();
const productStore = useProductStore();

const { isLoading } = storeToRefs(appStore);
const { isProductWritable, product } = storeToRefs(productStore);

const hasTermsGuide = ref<boolean>(true);

const terms = ref<RunningTermsAndVersionResponse[]>([]);

const firstTermHomeImageSrc = ref<Record<string, string>>(TermsHomeFirstImages);
const secondTermHomeImageSrc = ref<Record<string, string>>(TermsHomeSecondImages);

const firstTermsHomeImageByLocale = computed(
  () => firstTermHomeImageSrc.value[`Image_${locale.value.replace('-', '_')}`]
);
const secondTermsHomeImageByLocale = computed(
  () => secondTermHomeImageSrc.value[`Image_${locale.value.replace('-', '_')}`]
);

const handleOnVersionClick = async (termId: string) => {
  navigateToTermForm(MODE.EDIT, termId);
};

const navigateToTermForm = async (mode: string, id?: string) => {
  if (mode === MODE.REGISTER) {
    if (!(await checkProductPermission())) {
      return;
    }
  }

  const query = `${id ? `?id=${id}` : ''}`;
  redirectTo(`${PRODUCT_TERMS_PAGE_URL}/${mode}${query}`);
};

const handleVersionDelete = async (versionId: string) => {
  const result = await showDialog({
    component: shallowRef(DeleteDialog)
  });

  if (!product.value) {
    return;
  }

  if (result) {
    try {
      const res = await deleteTermApi(product.value.gameId, versionId);
      if (res) {
        await showAlert({
          severity: 'info',
          content: t('studio.common.popup_case_f_complete_del'),
          confirmClasses: 'max-w-full'
        });

        await init();
      }
    } catch (error) {
      showError(error);
    }
  }
};

const handleProjectsFold = (isFold: boolean) => {
  terms.value.forEach((term: RunningTermsAndVersionResponse) => {
    term.expand = !isFold;
  });
};

const showError = async (err: unknown) => {
  const error = err as ErrorResponse;
  const errorCode = error.statusCode ?? 0;

  await showAlert({
    severity: 'danger',
    content: errorCode.toString()
  });
};

const sortTermsByCreatedDate = (runningTerms: RunningTermContents[][]) => {
  // Sort the versions of each term by the latest created date
  runningTerms.forEach((term: RunningTermContents[]) => {
    if (term.length > 1) {
      term.sort((a: RunningTermContents, b: RunningTermContents) => {
        return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
      });
    }
  });

  // Sort all the terms by the latest created date
  const sortedTerms = runningTerms
    .map((term: RunningTermContents[]) => {
      return toRaw(term[0]);
    })
    .sort((a: RunningTermContents, b: RunningTermContents) => {
      return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
    });

  // Map the sorted terms to the original array
  const newTermArray = terms.value.slice();
  sortedTerms.forEach((term: RunningTermContents, index: number) => {
    const originalIndex = terms.value.findIndex(
      (newTerm: RunningTermsAndVersionResponse) =>
        newTerm.termsRunning.subCategory === term.subCategory
    );
    const originalTerm = terms.value[originalIndex];
    newTermArray[index] = originalTerm;
  });

  terms.value = newTermArray;
};

const getRunningTermsAndVersion = async () => {
  if (!product.value) {
    return;
  }

  const promises = Object.values(TERM_TYPES).map((term: string) => {
    return new Promise(
      (resolve: (value: Promise<RunningTermsAndVersionResponse | undefined>) => void) => {
        const res = fetchRunningTermsAndVersionApi({
          productNo: product.value?.gameId ?? '',
          termType: term
        });
        resolve(res);
      }
    );
  });
  const versions = await Promise.all(promises);

  if (versions) {
    terms.value = versions
      .filter((term: RunningTermsAndVersionResponse | undefined) => term !== undefined)
      .filter((term: RunningTermsAndVersionResponse) => term?.contents.length > 0);

    terms.value.forEach((term: RunningTermsAndVersionResponse) => {
      term.contents.forEach((content: RunningTermContents) => {
        content.subCategory = term.termsRunning.subCategory;
      });
    });
    const allContents = terms.value.map((term: RunningTermsAndVersionResponse) => term.contents);
    sortTermsByCreatedDate(allContents);
  }
};

const init = async () => {
  try {
    await getRunningTermsAndVersion();

    if (!product.value) {
      return;
    }
    const termSetting = await checkTermGuide(product.value.gameId);

    // Only show the guide if the Terms Of Service is not registered and the setting is 'Y'
    if (termSetting) {
      const isTermShown =
        termSetting.useYn &&
        termSetting.useYn === Confirmation.YES &&
        !terms.value.find(
          (term: RunningTermsAndVersionResponse) =>
            term.termsRunning.subCategory === TERM_TYPES.TERMS_OF_SERVICE
        );
      hasTermsGuide.value = isTermShown ?? false;
    }
  } catch (termTypeError) {
    showError(termTypeError);
  }
};

init();
</script>
