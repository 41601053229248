<template>
  <div>
    <s-dialog :open="true" size="sm" :to="`stds-dialog-delete-term-version`">
      <s-dialog-overlay />
      <s-dialog-panel>
        <st-dialog-header :isCloseButtonShown="false">
          {{ $t('studio.prj_prod_mngmt.prod_home.prod_terms.term_del_guide') }}
        </st-dialog-header>
        <s-dialog-content>
          <s-dialog-content-body>
            <safe-html
              as="p"
              class="text-md leading-lg font-regular text-on-surface-elevation-3 mb-4"
              :html="$t('studio.prj_prod_mngmt.prod_home.prod_terms.term_del_guide1')"
            />
            <input-text
              v-model="confirmText"
              placeholder="DELETE"
              inputClass="uppercase"
              :allowInputMaxLength="false"
              :clearable="false"
              :countable="false"
              maxLength="6"
            />
          </s-dialog-content-body>
          <s-dialog-footer class="w-full px-0">
            <s-button class="w-full" variant="outline" @click="onClose(false)">
              {{ $t('studio.common.popup_case_cancel_btn') }}
            </s-button>
            <s-button
              class="w-full"
              variant="red"
              :isDisabled="confirmText.toUpperCase() !== CONFIRM_TYPE.DELETE"
              @click="onClose(true)"
            >
              {{ $t('studio.prj_prod_mngmt.prod_home.prod_terms.term_del_cf_btn') }}
            </s-button>
          </s-dialog-footer>
        </s-dialog-content>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="`stds-dialog-delete-term-version`" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import InputText from '@/components/validation/input-text.vue';
import { CONFIRM_TYPE } from '@/constants/common.const';

const emits = defineEmits<{
  close: [v: boolean];
}>();

const confirmText = ref<string>('');

const onClose = (value: boolean) => {
  emits('close', value);
};
</script>
